/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react';
import {
  createTheme,
  responsiveFontSizes,
  ThemeOptions,
} from '@material-ui/core/styles/index.js';
import { ThemeProvider } from '@material-ui/styles';
import { COLORS } from '@seeeverything/ui.util/src/constants/constants.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { FONTS } from '../../common/constants.ts';

const BLUE = {
  light: COLORS.BLUE,
  dark: COLORS.BLUE,
  main: COLORS.BLUE,
  contrastText: '#FFFFFF',
};

const defaultThemeOptions: ThemeOptions = {
  palette: {
    primary: BLUE,
    secondary: BLUE,
    text: {
      disabled: '#888888',
    },
    common: {
      white: '#FFFFFF',
      black: color.format(-0.6),
    },
  },
  typography: {
    fontFamily: FONTS.Roboto.family,
    allVariants: {
      color: color.format(-0.8),
    },
  },
  overrides: {
    MuiFilledInput: {
      root: {
        '&:hover': {
          borderBottom: `solid 2px ${COLORS.BLUE}`,
        },
      },
      input: {
        cursor: 'text',
      },
    },
    MuiFormLabel: {
      root: {
        color: color.format(-0.45),
      },
    },
  },
};

export const theme = responsiveFontSizes(createTheme(defaultThemeOptions));

export interface IThemeProps {
  children: ReactNode;
}
export const Theme = ({ children }: IThemeProps) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
