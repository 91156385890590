export const FONTS = { Roboto: { family: "'Roboto', sans-serif" } };

export const Z_INDEX = {
  POPUP_OVERLAY: 99,
  OVERLAY_PANEL: 5, // Overlay Primitive.
};

// These options take into account the Query Builder and Sheet system,
// where all current consumers are situated.
export const INTERSECTION_OBSERVER_DEFAULTS = {
  rootMargin: '-194px 0px 0px 0px',
};
