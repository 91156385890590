import chroma from 'chroma-js';
import * as R from 'ramda';
export {
  average,
  bezier,
  blend,
  brewer,
  cmyk,
  contrast,
  css,
  cubehelix,
  deltaE,
  distance,
  gl,
  hex,
  hsl,
  hsv,
  interpolate,
  lab,
  lch,
  limits,
  mix,
  random,
  rgb,
  scale,
  temperature,
  valid,
} from 'chroma-js';

export const RED = 'rgba(255, 0, 0, 0.1)';

/**
 * Creates a new chroma instance
 * https://gka.github.io/chroma.js/.
 *
 * @example create('white') // White
 * @example create('#ffffff') // White
 * @example create(0xffffff) // White
 * @example create([255, 255, 255]) // White
 */
export function create(value: string | number | number[]): chroma.Color {
  if (Array.isArray(value)) {
    return chroma(value);
  }
  return chroma(value);
}

/** New chroma instance for the color black. */
export const black = () => chroma('black');

/** New chroma instance for the color white. */
export const white = () => chroma('white');

/**
 * Takes a value of various types and converts it into a color.
 *
 * @example format(true) // rgba(255, 0, 0, 0.1) (red)
 * @example format(-1)  // rgba(0, 0, 0, 1) (black)
 * @example format(0.5) // rgba(255, 255, 255, 0.5) (translucent white)
 */
export function format(
  value: string | number | boolean | undefined,
): string | undefined {
  if (value === undefined) {
    return undefined;
  }
  if (value === true) {
    return RED;
  }
  if (R.is(Number, value)) {
    return toGrayAlpha(value as number);
  }
  return value as string;
}

/**
 * Adjusts a chroma color's luminosity channel directly to a certain value
 *
 * @example luminosity(chroma('red'), 0.4)   // hsl(0, 100%, 40%)
 * @example luminosity(chroma('white'), 0.2)  // hsl(0, 0%, 20%)
 */
export const luminosity = (
  chromaColor: chroma.Color,
  adjustment: number | string,
) => {
  const isNumberOutOfRange =
    R.is(Number, adjustment) && (adjustment < 0 || adjustment > 1);
  return chromaColor.set('hsl.l', isNumberOutOfRange ? 1 : adjustment);
};

/**
 * A number between -1 (black) and 1 (white).
 */
export function toGrayAlpha(value: number): string {
  if (value < -1) {
    value = -1;
  }
  if (value > 1) {
    value = 1;
  }

  // Black.
  if (value < 0) {
    return chroma('black').alpha(Math.abs(value)).hex();
  }

  // White.
  if (value > 0) {
    return chroma('white').alpha(value).hex();
  }

  return 'rgba(0, 0, 0, 0.0)'; // Transparent.
}

/**
 * A number between -1 (black) and 1 (white).
 *
 * @example
 * toGrayHex(-1) // #000000
 */
export function toGrayHex(value: number): string {
  if (value < 0) {
    const adjustedValue = value < -1 ? -1 : value;
    return black()
      .set('hsl.l', 1 - Math.abs(adjustedValue))
      .hex();
  }
  if (value > 0) {
    const adjustedValue = value > 1 ? 1 : value;
    return white().set('hsl.l', adjustedValue).hex();
  }

  return white().hex();
}
