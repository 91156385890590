/** @jsxImportSource @emotion/react */
import Head from 'next/head.js';
import { AppProps } from 'next/app.js';
import { Theme } from '@seeeverything/ui.primitives/src/components/Theme/Theme.tsx';
import '../styles/global.css';
import '../styles/normalize.css';

export default function App({ Component, pageProps }: AppProps) {
  return (
    <div>
      <Head>
        <meta
          name={'viewport'}
          content={'width=device-width, initial-scale=1'}
          key={'viewport'}
        />
        <title>{'SeeEverything'}</title>
      </Head>
      <Theme>
        <Component {...pageProps} />
      </Theme>
    </div>
  );
}
